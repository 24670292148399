import React from "react"
import { Layout } from "@components/layout"
import { PostItem } from "@components/blog"

import {
  blog,
  blog__wrapper,
  blog__wrapper__headers,
  blog__wrapper__posts,
} from "./styles/blog.module.scss"

const Blog = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  const gladiatorsPosts = posts.filter(
    (el) =>
      el.categories.nodes[0].slug === "nagrania-live-z-gladiatorow-javascriptu"
  )
  return (
    <Layout {...page}>
      <div className={blog}>
        <div className={blog__wrapper}>
          <div className={blog__wrapper__headers}>
            <h1>Witaj na Blogu!</h1>
            <p>
              Tutaj odnajdziesz wszystkie informacje o spółeczności Gladiatorów
              Javascriptu
            </p>
          </div>
          <div className={blog__wrapper__posts}>
            {gladiatorsPosts.map((el, k) => (
              <PostItem {...el} key={k} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Blog
